/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

.maintenance,
.construction {
	text-align: center !important;
}

.maintenance div,
.construction div {
	margin: 32px 0 48px 0;
	text-align: center !important;
}
