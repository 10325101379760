/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

.dm-tools .dm-tools-content {
  text-align: left;
}

.dm-tools .dm-tools-content .nav-tabs {
  border-bottom: 1px solid var(--font-color);
  padding-bottom: 12px;
}

#app .dm-tools .dm-tools-content .nav-tabs button {
  background: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  color: var(--font-color);
  display: inline-block;
  font-size: 24px;
  margin: 0 24px;
}

#app .dm-tools .dm-tools-content .nav-tabs button:hover {
  background: none;
  color: var(--font-color-hover);
}

#app .dm-tools .dm-tools-content .nav-tabs button.isSelected {
  border-color: var(--secondary-color-1);
}

.dm-tools .dm-tools-content table {
  width: 100%;
}

.dm-tools .dm-tools-content table td {
  vertical-align: top;
}

.dm-tools .dm-tools-content table td.first-column {
  vertical-align: top;
  width: 40%;
}

.dm-tools .dm-tools-content table td.second-column {
  vertical-align: top;
  width: 60%;
}

.dm-tools .dm-tools-content input {
  margin: 8px;
  padding: 4px;
  width: 90%;
}

.dm-tools .dm-tools-content .spell-frame {
  border: 1px solid var(--secondary-color-2);
  height: 500px;
  margin-bottom: 24px;
  width: 95%;
}
