/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

.contact {
  text-align: center;
}

.contact .form {
  display: inline-block;
  text-align: left;
  width: fit-content;
}

.mobile .contact .form {
  margin: 8px;
  width: 100%;
}

.contact .form .fieldset {
  border: none;
  margin-top: 18px;
}

.contact .form .fieldset legend {
  font-size: 18px;
  text-align: left;
}

.contact .form .fieldset input {
  display: block;
  padding: 8px;
  width: 450px;
}

.mobile .contact .form .fieldset input {
  width: calc(100% - 35px);
}

.contact .form .fieldset div {
  display: inline-block;
}

.mobile .contact .form .fieldset div {
  display: block;
}

.contact .form .fieldset div input {
  width: 210px;
}

.contact .form .fieldset div.first-name-group {
  margin-right: 10px;
}

.mobile .contact .form .fieldset div.first-name-group {
  margin-right: 0;
}

.contact .form .fieldset textarea {
  display: block;
  min-height: 75px;
  max-width: 450px;
  padding: 8px;
  width: 450px;
}

.mobile .contact .form .fieldset textarea {
  max-width: calc(100% - 35px);
  width: calc(100% - 35px);
}

.contact .form .fieldset label {
  font-size: 14px;
  margin-left: 1px;
  margin-top: 4px;
  text-align: left;
}

.contact .form .button {
  margin-top: 24px;
  text-align: center;
}

.contact .form button {
  font-size: 18px;
  padding: 8px 16px;
}
