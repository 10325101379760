/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

header.header {
  background-color: var(--primary-color-1);
  border-bottom: 1px solid var(--font-color);
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
}

header.header h1 {
  display: inline-block;
  font-family: Georgia;
  font-weight: 400;
  margin: 16px 16px 16px 32px;
}

.mobile header.header h1 {
  margin-left: 16px;
}

header.header .navButton {
  float: right;
  height: 42px;
  margin: 12px 12px 12px 0;
  width: 42px;
}

header.header .navButton hr {
  border-bottom: none;
  border-top: 4px solid var(--secondary-color-1);
  border-radius: 2px;
  margin: 5px 2px;
}

header.header div {
  display: inline-block;
}

header.header div.links {
  float: right;
}

header.header div.links h2 {
  border-bottom: 2px solid transparent;
  display: inline-block;
  margin-right: 52px;
  margin-bottom: 18px;
  vertical-align: bottom;
}

header.header div.links h2.isSelected {
  border-color: var(--secondary-color-1);
}
