/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

.footer {
	font-size: 13px;
	height: 42px;
	margin: -50px 42px 0 42px;
	padding: 8px 8px 0 8px;
	text-align: center;
}

.footer .barrier {
	margin: 0 8px 0 8px;
}
