/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

:root {
  --font-color: #fff;
  --font-hover-color: #dedede;
  --primary-color-1: #1f2833;
  --primary-color-2: #0b0c10;
  --secondary-color-1: #45a29e;
  --secondary-color-2: #57d5cb /*#66fcf1*/;
  --terniary-color: #c5c6c7;

  /* Amplify Start */
  --amplify-font-family: Trebuchet MS;
  --amplify-primary-color: #45a29e;
  --amplify-primary-tint: #1f2833;
  --amplify-primary-shade: #1f2833;
  /* Amplify End */
}

html,
body {
  height: calc(100vh - 80px);
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-color-1);
  color: #eee;
  font-family: Trebuchet MS;
  letter-spacing: 0.1px;
}

#app {
  height: calc(100vh - 80px);
  margin: 0;
}

#app a {
  color: var(--font-color);
  text-decoration: none;
}

#app a:hover {
  color: var(--font-hover-color);
  cursor: pointer;
}

#app button {
  border: none;
  border-radius: 3px;
  color: var(--primary-color-2);
  font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
}

#app button:hover {
  background-color: var(--font-hover-color);
  cursor: pointer;
}

#app button:disabled {
  cursor: not-allowed;
}

#app button:focus {
  outline: none;
  box-shadow: none;
}

#app .pull-right {
  float: right;
}

#app .app-contents-wrapper {
  min-height: calc(100vh - 80px);
}

#app .app-contents {
  margin-top: 80px;
  min-height: 100%;
  padding: 20px 20px 50px 20px;
}

#app .mobile .app-contents {
  margin-top: 55px;
  padding: 10px 10px 50px 10px;
}

#app .push {
  display: inline-block;
  width: 100%;
}

/* Amplify Start */
#app amplify-authenticator {
  display: block;
  height: 600px;
  margin-top: -100px;
  max-height: 600px;
  position: relative;
  vertical-align: top;
}

#app amplify-authenticator amplify-sign-in {
  padding-top: 100px;
  position: absolute;
  top: 12px;
}
/* Amplify End */
