/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

.campaign {
  height: 100%;
}

.campaign h1.campaign-header {
  height: 100%;
  margin-right: 350px;
}

.mobile .campaign h1.campaign-header,
.campaign h1.campaign-header.editing {
  margin-right: 0;
}

.campaign .campaign-contents {
  height: 100%;
  padding-right: 350px;
}

.mobile .campaign .campaign-contents {
  padding-right: 0;
}

.campaign .campaign-contents div {
  text-align: left;
}

.campaign .mce-toc {
  border: 1px solid var(--font-color);
  max-height: calc(100vh - 160px);
  overflow: auto;
  padding: 8px;
  position: fixed;
  right: 72px;
  top: 100px;
  width: 256px;
}

.mobile .campaign .mce-toc {
  position: inherit;
}

.campaign .mce-toc h2 {
  margin: 12px;
}

.campaign .mce-toc ul {
  list-style-type: none;
  padding-left: 16px;
}

#app .campaign button.edit-button {
  background: none !important;
  border: none !important;
  color: var(--font-color) !important;
  font-size: 16px;
  margin: 11px 0 0 20px;
  padding: 0;
  vertical-align: top;
}

#app .campaign button.edit-button:hover {
  color: var(--font-hover-color) !important;
}

#app .campaign button.cancel-button,
#app .campaign button.save-button {
  font-weight: 500;
  font-size: 18px;
  margin: 0 8px 8px 8px;
  padding: 8px;
  width: 100px;
}

#app .campaign button.save-button {
  background-color: var(--secondary-color-1);
  color: var(--font-color) !important;
}

#app .campaign button.save-button:hover {
  background-color: var(--secondary-color-2) !important;
  color: var(--primary-color-1) !important;
}

#app .campaign button.copy-link {
  background: none;
  color: var(--font-color-hover);
  margin-left: 12px;
  vertical-align: middle;
}

#app .campaign .last-updated {
  font-size: 13px;
  padding: 28px;
}

/* TinyMCE Start */
/*Container, container body, iframe*/
.tox-tinymce,
.tox-editor-container {
  min-height: calc(100vh - 300px) !important;
}

/*Container body*/
.tox-sidebar-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/*Editing area*/
.tox-edit-area {
  margin-bottom: 20px !important;
  position: absolute;
}

.tox-edit-area iframe {
  padding-bottom: 8px;
}

/*Footer*/
.tox-tinymce .tox-statusbar {
  position: absolute !important;
  bottom: 0;
  left: 0;
  right: 0;
}
/* TinyMCE End */
