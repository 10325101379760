/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

.dnd {
  height: 100%;
  text-align: center;
}

.dnd .campaign-card {
  padding: 12px 0 24px 0;
  position: relative;
}

.mobile .dnd .campaign-card {
  margin-bottom: -6px;
}

.dnd .campaign-card .edit-card {
  border: 1px solid var(--secondary-color-2);
  height: 332px;
  margin-top: 2px;
  padding: 8px 133px 8px 8px;
  text-align: left;
}

.mobile .dnd .campaign-card .edit-card {
  height: 232px;
}

.dnd .campaign-card .edit-card .input {
  display: block;
  margin: 12px 0 0 24px;
  padding: 8px;
  width: 80%;
}

.mobile .dnd .campaign-card .edit-card .input {
  margin: 0 0 0 24px;
  padding: 0;
}

.dnd .campaign-card .edit-card .input input {
  padding: 9px 8px;
  width: 80%;
}

.dnd .campaign-card .edit-card .input label {
  display: block;
  margin: 8px;
}

.dnd .campaign-card button.cancel-button {
  border-radius: 0px !important;
  font-size: 18px;
  height: 175px;
  overflow: hidden;
  position: absolute;
  right: -2px;
  top: 14px;
  width: 125px;
  z-index: 101;
}

.mobile .dnd .campaign-card button.cancel-button {
  height: 125px;
}

.dnd .campaign-card button.save-button {
  background-color: var(--secondary-color-2);
  border-radius: 0px !important;
  border-top: 1px solid var(--primary-color-1) !important;
  font-size: 18px;
  height: 175px;
  overflow: hidden;
  position: absolute;
  right: -2px;
  top: 189px;
  width: 125px;
  z-index: 101;
}

.mobile .dnd .campaign-card button.save-button {
  height: 125px;
  top: 139px;
}

.dnd .campaign-card button.edit-button {
  background-color: var(--secondary-color-2);
  border-radius: 0px !important;
  font-size: 18px;
  height: 350px;
  overflow: hidden;
  position: absolute;
  right: -2px;
  top: 14px;
  transform: translateX(62px) scale(0, 1);
  width: 125px;
  z-index: 101;
}

.dnd button.create-button {
  background-color: var(--secondary-color-2) !important;
  font-size: 18px;
  padding: 24px;
  width: 100%;
}

.dnd .campaign-card button.edit-button:hover,
.dnd .campaign-card button.save-button:hover,
.dnd button.create-button:hover {
  background-color: var(--secondary-color-1) !important;
}

.dnd .campaign-card:hover button.edit-button {
  transform: translateX(0px) scale(1, 1);
  transition-duration: 0.6s;
}

.mobile .dnd .campaign-card button.edit-button {
  height: 250px;
}

.dnd .campaign-card .campaign-link {
  margin: 0;
  padding: 1px;
  position: relative;
  text-align: left;
  width: 100%;
  z-index: 100;
}

.dnd .campaign-card .campaign-link h1 {
  color: var(--font-color);
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  z-index: 101;
}

.dnd .campaign-card .campaign-link:hover h1 {
  color: var(--secondary-color-2);
}

#app button.create-button {
  padding: 12px;
  width: 100%;
}

.dnd .campaign-card .campaign-link .campaign-image {
  border: solid 1px transparent;
  height: 350px;
  width: 100%;
  object-fit: cover;
  object-position: 0% 45%;
}

.mobile .dnd .campaign-card .campaign-link .campaign-image {
  height: 250px;
}

.dnd .campaign-card:hover .campaign-link .campaign-image {
  border-color: var(--secondary-color-2);
}
